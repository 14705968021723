
<template>
  
</template>
<script>
import { ajaxCallMixin } from "@/mixins/HttpCommon";
import { localDb } from "@/mixins/localDb";

export default {
  name: "AutoLogin",
  mixins: [ajaxCallMixin, localDb],
  data() {
    return {
      username: "",
      password: "",
      currentAlertCounter: 0,
      testStatus: {
        0: "Not Attempted",
        1: "Attempted",
      },
    };
  },
  beforeMount(){
    this.autoLogin();
  },
  methods: {
    loginUser() {
      var data = {};
      data.username = this.username;
      data.password = this.password;
      var url = "/test/test/testLogin";
      var params = {};
      params.data = data;
      this.ajaxCall(url, params, this.saveExamData);
    },
    saveExamData(apiResponse) {
       if (apiResponse.error != true) {
        // to check if candidate has attempted the test before or not
        let flag = apiResponse.data.testStatus;
        if (flag === null || this.testStatus[flag] === "Not Attempted") {
          this.save("examData", apiResponse.data, "local");
          this.save("testToken", apiResponse.data.authToken, "local");
          this.$router.push("/exam/details");
        } else if (this.testStatus[flag] === "Attempted") {
          this.save("examData", apiResponse.data, "local");
          this.save("testToken", apiResponse.data.authToken, "local");
          this.$router.push("/exam/result");
          this.currentAlertCounter = 5;
          this.message = "You have submitted the test.";
          this.color = "success";
        }
      } else {
        this.currentAlertCounter = 5;
        this.message = "Incorrect credentials please check.";
        this.color = "danger";
      }
    },
    autoLogin(){
      var autoLoginKey= this.$route.params.autoLoginKey;
      
      var logins = JSON.parse(window.atob(autoLoginKey));
      this.username = logins["username"];
      this.password = logins["password"];
      this.loginUser();
    }
  },
};
</script>

<style scoped>
.login-bgd {
  background-image: url("../assets/images/csbgd.png");
  background-size: cover;
}
</style>